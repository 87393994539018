<template>
<div class="f-wrap">

<div class="f-full">

  <SinglePage>

    <div class="grid-x align-center">
      <div class="cell small-12 medium-7">
   <h1>360-visning</h1>

<p>Den senaste tiden har lärt oss att det finns tillfällen då man skall undvika sociala kontakter. Men alla vet ju att man inte kan få en bra uppfattning av en lägenhet bara från bilder. Dåligt ljus, foton med en gammal kamera.</p>


<p>Vi har nu möjlighet att erbjuda <strong>360-fotografering</strong> av din lägenhet, i både <strong>Göteborg</strong> och <strong>Stockholm</strong>.</p>
   
   
  <h2>Hur funkar det?</h2>
  <p>Hör av dig till oss på <a href="mailto:360@bytbostad.se">360@bytbostad.se</a>, skicka med en länk till din annons.</p>
  <p>Vi kommer att återkomma med möjliga tider för fotografering, när du bokat in en fotografering är det bara att vänta.</p>
<p>Själva fotograferingen går på ungefär 45 minuter till 1,5 timme beroende på hur stor din lägenhet är.</p>
  <p>360-visningen är klar inom 24 timmar, och kommer automatiskt att läggas till på din annons.</p>
  <h2>Vad kostar det?</h2>

<p>Tjänsten är bara tillgänglig för betalande medlemmar på BytBostad.</p>

<ul>
  <li>
<em>999</em> kronor för fotograferingen av bostäder upp till 50 m<sup>2</sup>
  </li>
<li>
<em>1 249</em> kronor för lägenheter upp till 100 m<sup>2</sup>
</li>
<li>
<em>1495</em> kronor för lägenheter upp till 150 m<sup>2</sup> 
</li>
  </ul>
  <p>Om din bostad är över 150 kvadratmeter kan du maila oss för en offert.</p>
  <p>
    360-visningen kommer att vara tillgänglig under hela den tid du är medlem på BytBostad.</p>
    
    
    <h2>Checklista innan vi fotograferar</h2>
    
    <ul>
      <li>
        Städa lägenheten ordentligt.
      </li>
      <li>
        Plocka undan så mycket saker du kan. Lägg in jackor och skor i garderoben, ställ undan saker som inte behöver stå framme.
      </li>
      <li>
Plocka bort personliga saker som fotografier eller annat du inte vill att andra skall se.
</li>
<li>
  Tänd alla lampor. Bilderna blir mycket bättre med ordentligt ljus.
  </li>

        </ul>

        <h2>Kom igång nu!</h2>

          <p>Skicka ett mail till <a href="mailto:360@bytbostad.se">360@bytbostad.se</a>, glöm inte att länka till din annons!</p>


      </div></div>


  </SinglePage>


</div>

  <div class="f-two">
  <iframe style="width:100%;height:75vh" src='https://my.matterport.com/show/?m=EVNYVVSGEuZ' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
      <iframe style="width:100%;height:75vh" src='https://my.matterport.com/show/?m=tgpdeYVYnyE' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
  </div>


 
    <!-- <div class="grid-x align-center">
      <div class="cell small-12 medium-7"> -->
       

 <!-- <router-link tag="button"
                             class="button"
                             :to="{ name: 'adlist' }"
                             style="align-self:center;justify-self:right;text-decoration:none;">
                  Hitta ett byte
                </router-link> -->
  
      <!-- </div>
    </div> -->
    </div>
</template>
<style lang="scss" scoped>
@import '@/scss/main.scss';

h1,h2,h3,p{
  padding-bottom:20px;
}
.grid-container{
  padding-bottom:20px;
}

.f-grid{
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 200px;
}

.f-two{

  display:grid;
  grid-template-columns: repeat( auto-fit, minmax(480px, 1fr) );


}
.f-left{
  grid-column: 1;
}
.f-right{
  grid-column: 2;
}
.f-full{
  grid-column: 1 / -1;
}
</style>
<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage
  }
}
</script>

